<oaman-detail-actions
  *ngIf="this.context === 'enroll'"
  [actions]="authActions"
  (clickTriggered)="openDialog($event)"
></oaman-detail-actions>
<div fxLayout="row wrap" fxLayout.lt-sm="column wrap">
  <div fxFlex="25">
    <div fxFlex fxLayout="column wrap" class="filter-wrapper">
      <mat-card appearance="raised" class="filter-card-content">
        <mat-card-content>
          <mat-checkbox
            id="filterExpiredDeputies"
            [(ngModel)]="isChecked"
            (change)="onFilter()"
            >{{
              'fundingProject.details.authorization.filter.expiredDeputies'
                | translate
            }}
          </mat-checkbox>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div fxFlex>
    <div class="table-wrapper">
      <mat-table id="deputies-table" [dataSource]="dataSource" matSort>
        <!-- Name column -->
        <ng-container matColumnDef="name">
          <mat-header-cell
            [title]="
              'fundingProject.details.authorization.table.a11y.name' | translate
            "
            *matHeaderCellDef
            mat-sort-header
          >
            {{ 'fundingProject.details.authorization.table.name' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ extractUserFullNameOrOrganizationName(element) }}
          </mat-cell>
        </ng-container>

        <!-- Role column -->
        <ng-container matColumnDef="participantRole">
          <mat-header-cell
            [title]="
              'fundingProject.details.authorization.table.a11y.participantRole'
                | translate
            "
            *matHeaderCellDef
            mat-sort-header
          >
            {{
              'fundingProject.details.authorization.table.participantRole'
                | translate
            }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{
              'fundingProject.details.authorization.roles.' +
                element.participantRole | translate
            }}
          </mat-cell>
        </ng-container>

        <!-- ValidFrom Column -->
        <ng-container matColumnDef="validFrom">
          <mat-header-cell
            [title]="
              'fundingProject.details.authorization.table.a11y.validFrom'
                | translate
            "
            *matHeaderCellDef
            mat-sort-header
          >
            {{
              'fundingProject.details.authorization.table.validFrom' | translate
            }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.validFrom | date: 'dd.MM.yyyy' }}
          </mat-cell>
        </ng-container>

        <!-- ValidTo Column -->
        <ng-container matColumnDef="validTo">
          <mat-header-cell
            [title]="
              'fundingProject.details.authorization.table.a11y.validTo'
                | translate
            "
            *matHeaderCellDef
            mat-sort-header
          >
            {{
              'fundingProject.details.authorization.table.validTo' | translate
            }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{
              (element?.validTo | date: 'dd.MM.yyyy') ||
                ('fundingProject.details.authorization.table.unlimited'
                  | translate)
            }}
          </mat-cell>
        </ng-container>

        <!-- attorneyPowerStartDate Column -->
        <ng-container matColumnDef="attorneyPowerStartDate">
          <mat-header-cell
            [title]="
              'fundingProject.details.authorization.table.a11y.startDateAttorneyPower'
                | translate
            "
            *matHeaderCellDef
            mat-sort-header
          >
            {{
              'fundingProject.details.authorization.table.startDateAttorneyPower'
                | translate
            }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.attorneyPowerStartDate | date: 'dd.MM.yyyy' }}
          </mat-cell>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action" *ngIf="this.context === 'enroll'">
          <mat-header-cell class="last-column" *matHeaderCellDef>
          </mat-header-cell>
          <mat-cell class="last-column" *matCellDef="let element">
            <mat-icon
              #matMenuTrigger="matMenuTrigger"
              (keyup.enter)="onKeyupEnter()"
              [matMenuTriggerFor]="menu"
              role="button"
              tabindex="0"
              matTooltip="{{
                'fundingProject.details.authorization.table.more' | translate
              }}"
              attr.aria-label="{{
                'fundingProject.details.authorization.table.more' | translate
              }}"
              class="action-icon"
              *ngIf="element"
              >more_horiz
            </mat-icon>
            <mat-menu #menu="matMenu" xPosition="before">
              <div>
                <button
                  mat-menu-item
                  class="dropdown-item"
                  (click)="endValidation(element)"
                  [disabled]="
                    isButtonDisabled(element, inactivateDeputyActionKey)
                  "
                  [matTooltip]="
                    setEndValidationTooltip(element, inactivateDeputyActionKey)
                  "
                >
                  {{
                    'fundingProject.details.authorization.table.dropdown.endValidation'
                      | translate
                  }}
                </button>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row
          id="header-row"
          *matHeaderRowDef="displayedColumns"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        id="paginator"
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
      <p class="tz-hint">
        {{ 'fundingProject.details.authorization.table.timezone' | translate }}
        {{ currentDate | date: 'zzzz' }}
      </p>
    </div>
  </div>
</div>
