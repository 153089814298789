import { formatDate } from '@angular/common';
import { DeputyInfo } from '../../../../../core/interfaces/deputy-info';

export function getCurrentDate() {
  const currentDate = new Date();
  return formatDate(currentDate, 'yyyy-MM-ddTHH:mm:ss', 'en-US');
}

export function isValidDeputy(deputy: DeputyInfo): boolean {
  if (deputy) {
    const currentDateStr = getCurrentDate();
    return !deputy.validTo || currentDateStr <= deputy.validTo;
  }
  return false;
}

export function isValidEndedDeputy(deputy: DeputyInfo): boolean {
  const currentDateStr = getCurrentDate();
  return !!(deputy.validTo && currentDateStr >= deputy.validTo);
}
